import { PropsWithChildren, ReactElement } from 'react';

import { StickyBanner } from '../components/StickyBanner/';
import { useChannelTalk } from '../lib/channelTalk';

import { GnbHeader } from './';
const RootLayout = (props: PropsWithChildren) => {
  useChannelTalk();

  return (
    <>
      <StickyBanner />
      <GnbHeader />
      {props.children}
    </>
  );
};
export function getRootLayout(page: ReactElement) {
  return <RootLayout>{page}</RootLayout>;
}
