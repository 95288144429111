import { PropsWithChildren, ReactElement } from 'react';
import { StickyBanner } from '@zep/components/StickyBanner';

import { useChannelTalk } from '../lib/channelTalk';

export function getBannerLayout(page: ReactElement) {
  return <BannerLayout>{page}</BannerLayout>;
}

const BannerLayout = (props: PropsWithChildren) => {
  useChannelTalk();

  return (
    <>
      <StickyBanner />
      {props.children}
    </>
  );
};
