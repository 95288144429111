import { Config } from 'tailwindcss';
import { PluginAPI } from 'tailwindcss/types/config';

import { Breakpoints, Colors } from './src/ui/ui-tokens';

const transformArrayToObject = (colors: string[]) =>
  colors.reduce<Record<string, string>>((acc, color, index) => {
    acc[index + 1] = color;
    return acc;
  }, {});

/**
 * Storybook과 twMerge 함수에서 해당 객체를 참조하고 있습니다.
 * 내용 변경 시, Storybook과 twMerge 함수에 반영이 필요한지 확인해 주세요.
 */
const config: Config = {
  darkMode: ['class'],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
  ],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    container: {
      center: true,
      padding: '2rem',
    },
    borderRadius: {
      DEFAULT: '8px',
      full: '9999px',
      sm: '6px',
      none: '0',
      large: '12px',
    },

    extend: {
      lineHeight: {
        normal: '140%',
      },
      borderRadius: {
        none: '0px',
        '2xs': '4px',
        xs: '6px',
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '32px',
      },
      spacing: {
        none: '0px',
        '4xs': '2px',
        '3xs': '4px',
        '2xs': '6px',
        xs: '8px',
        sm: '12px',
        md: '16px',
        lg: '24px',
        xl: '32px',
        '2xl': '40px',
        '3xl': '64px',
        '4xl': '80px',
      },
      colors: {
        // *** Solid Colors ***
        white: Colors.White,
        black: Colors.Black,
        violet: {
          50: Colors.Violet50,
          100: Colors.Violet100,
          200: Colors.Violet200,
          300: Colors.Violet300,
          400: Colors.Violet400,
          500: Colors.Violet500,
          600: Colors.Violet600,
          700: Colors.Violet700,
          800: Colors.Violet800,
          900: Colors.Violet900,
        },
        gray: {
          50: Colors.Gray50,
          100: Colors.Gray100,
          200: Colors.Gray200,
          300: Colors.Gray300,
          400: Colors.Gray400,
          500: Colors.Gray500,
          600: Colors.Gray600,
          700: Colors.Gray700,
          800: Colors.Gray800,
          900: Colors.Gray900,
        },
        mint: {
          50: Colors.Mint50,
          100: Colors.Mint100,
          200: Colors.Mint200,
          300: Colors.Mint300,
          400: Colors.Mint400,
          500: Colors.Mint500,
          600: Colors.Mint600,
          700: Colors.Mint700,
          800: Colors.Mint800,
          900: Colors.Mint900,
        },
        red: {
          50: Colors.Red50,
          100: Colors.Red100,
          200: Colors.Red200,
          300: Colors.Red300,
          400: Colors.Red400,
          500: Colors.Red500,
          600: Colors.Red600,
          700: Colors.Red700,
          800: Colors.Red800,
          900: Colors.Red900,
        },
        yellow: {
          50: Colors.Yellow50,
          100: Colors.Yellow100,
          200: Colors.Yellow200,
          300: Colors.Yellow300,
          400: Colors.Yellow400,
          500: Colors.Yellow500,
          600: Colors.Yellow600,
          700: Colors.Yellow700,
          800: Colors.Yellow800,
          900: Colors.Yellow900,
        },
        green: {
          50: Colors.Green50,
          100: Colors.Green100,
          200: Colors.Green200,
          300: Colors.Green300,
          400: Colors.Green400,
          500: Colors.Green500,
          600: Colors.Green600,
          700: Colors.Green700,
          800: Colors.Green800,
          900: Colors.Green900,
        },
        blue: {
          50: Colors.Blue50,
          100: Colors.Blue100,
          200: Colors.Blue200,
          300: Colors.Blue300,
          400: Colors.Blue400,
          500: Colors.Blue500,
          600: Colors.Blue600,
          700: Colors.Blue700,
          800: Colors.Blue800,
          900: Colors.Blue900,
        },
        purple: {
          50: Colors.Purple50,
          100: Colors.Purple100,
          200: Colors.Purple200,
          300: Colors.Purple300,
          400: Colors.Purple400,
          500: Colors.Purple500,
          600: Colors.Purple600,
          700: Colors.Purple700,
          800: Colors.Purple800,
          900: Colors.Purple900,
        },
        pink: {
          50: Colors.Pink50,
          100: Colors.Pink100,
          200: Colors.Pink200,
          300: Colors.Pink300,
          400: Colors.Pink400,
          500: Colors.Pink500,
          600: Colors.Pink600,
          700: Colors.Pink700,
          800: Colors.Pink800,
          900: Colors.Pink900,
        },
        // *** End of Solid Colors ***

        // *** Opacity Colors ***
        opacity: {
          white: transformArrayToObject(Colors.OpacityWhiteColors),
          black: transformArrayToObject(Colors.OpacityBlackColors),
          violet: transformArrayToObject(Colors.OpacityVioletColors),
        },
        // *** End of Opacity Colors ***

        // *** Alias Colors ***
        primary: {
          DEFAULT: Colors.Violet500,
          default: Colors.Violet500,
          disabled: Colors.Violet200,
          strong: Colors.Violet600,
        },
        status: {
          positive: Colors.Green600,
          cautionary: Colors.Yellow600,
          destructive: Colors.Red500,
        },
        accent: {
          red: Colors.Red400,
          yellow: Colors.Yellow500,
          green: Colors.Green500,
          mint: Colors.Mint500,
          blue: Colors.Blue500,
          purple: Colors.Purple500,
          pink: Colors.Pink400,
          foreground: 'hsl(var(--accent-foreground))',
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))',
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))',
        },
        // *** End of Alias Colors ***
      },

      fontWeight: {
        regular: '400',
      },
      // Text Alias Colors
      textColor: ({ theme }) => ({
        DEFAULT: Colors.Gray900,
        default: Colors.Gray900,
        neutral: Colors.Gray700,
        alternative: Colors.Gray600,
        assistive: Colors.Gray500,
        disabled: Colors.Gray300,
        inverse: Colors.White,
        strong: theme('colors.primary.strong'),
        destructive: Colors.Red500,
        positive: Colors.Green700,
        link: Colors.Blue500,
      }),
      placeholderColor: ({ theme }) => ({
        DEFAULT: Colors.Gray900,
        default: Colors.Gray900,
        neutral: Colors.Gray700,
        alternative: Colors.Gray600,
        assistive: Colors.Gray500,
        disabled: Colors.Gray300,
        inverse: Colors.White,
        strong: theme('colors.primary.strong'),
        destructive: Colors.Red500,
        positive: Colors.Green700,
        link: Colors.Blue500,
      }),
      // Background Elias Colors
      backgroundColor: ({ theme }) => ({
        DEFAULT: Colors.White,
        default: Colors.White,
        neutral: Colors.Gray50,
        alternative: Colors.Gray100,
        inverse: {
          DEFAULT: Colors.Black,
          default: Colors.Black,
          neutral: Colors.Gray900,
          alternative: Colors.Gray800,
        },
        strong: {
          DEFAULT: theme('colors.primary.default'),
          default: theme('colors.primary.default'),
          alternative: Colors.Violet50,
        },
        dimmed: theme('colors.opacity.black.4'),
      }),
      // Border Elias Colors
      borderColor: ({ theme }) => ({
        default: Colors.Gray400,
        neutral: Colors.Gray300,
        alternative: Colors.Gray200,
        assistive: Colors.Gray100,
        inverse: Colors.White,
        strong: {
          DEFAULT: Colors.Violet300,
          default: Colors.Violet300,
          assistive: Colors.Violet200,
        },
        destructive: {
          DEFAULT: Colors.Red300,
          default: Colors.Red300,
          assistive: Colors.Red200,
        },
        positive: {
          DEFAULT: Colors.Green300,
          default: Colors.Green300,
          assistive: Colors.Green100,
        },
        opacity: theme('colors.opacity.black.1'),
      }),
      // Icon Elias Colors
      fill: ({ theme }) => ({
        DEFAULT: Colors.Gray700,
        default: Colors.Gray700,
        neutral: Colors.Gray600,
        alternative: Colors.Gray500,
        assistive: Colors.Gray400,
        disabled: Colors.Gray300,
        inverse: Colors.White,
        strong: theme('colors.primary.default'),
        destructive: theme('colors.status.destructive'),
        cautionary: theme('colors.status.cautionary'),
        positive: theme('colors.status.positive'),
        opacity: theme('colors.opacity.black.1'),
      }),
      boxShadow: ({ theme }) => ({
        xs: `0px 2px 6px 0px ${theme('colors.opacity.black.1')}`,
        sm: `0px 4px 8px 0px ${theme('colors.opacity.black.2')}`,
        md: `0px 4px 8px 2px ${theme('colors.opacity.black.3')}`,
        lg: `0px 8px 12px 2px ${theme('colors.opacity.black.3')}`,
        xl: `0px 12px 16px 4px ${theme('colors.opacity.black.3')}`,
        'reversed-xs': `0px -2px 6px 0px ${theme('colors.opacity.black.1')}`,
        'reversed-sm': `0px -4px 8px 0px ${theme('colors.opacity.black.2')}`,
        'reversed-md': `0px -4px 8px 2px ${theme('colors.opacity.black.3')}`,
        'reversed-lg': `0px -8px 12px 2px ${theme('colors.opacity.black.3')}`,
        'reversed-xl': `0px -12px 16px 4px ${theme('colors.opacity.black.3')}`,
      }),
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },

      fontSize: {
        'display-md-extrabold': [
          '48px',
          { fontWeight: '800', lineHeight: '130%' },
        ],
        'display-sm-extrabold': [
          '40px',
          { fontWeight: '800', lineHeight: '130%' },
        ],
        'heading-xl-extrabold': [
          '30px',
          { fontWeight: '800', lineHeight: '130%' },
        ],
        'heading-lg-extrabold': [
          '24px',
          { fontWeight: '800', lineHeight: '130%' },
        ],
        'heading-md-bold': ['20px', { fontWeight: '700', lineHeight: '140%' }],
        'heading-sm-bold': ['18px', { fontWeight: '700', lineHeight: '140%' }],
        'body-lg-bold': ['16px', { fontWeight: '700', lineHeight: '140%' }],
        'body-lg-semibold': ['16px', { fontWeight: '600', lineHeight: '140%' }],
        'body-lg-medium': ['16px', { fontWeight: '500', lineHeight: '140%' }],
        'body-lg-regular': ['16px', { fontWeight: '400', lineHeight: '140%' }],
        'body-md-bold': ['14px', { fontWeight: '700', lineHeight: '140%' }],
        'body-md-semibold': ['14px', { fontWeight: '600', lineHeight: '140%' }],
        'body-md-medium': ['14px', { fontWeight: '500', lineHeight: '140%' }],
        'body-md-regular': ['14px', { fontWeight: '400', lineHeight: '140%' }],
        'body-sm-bold': ['13px', { fontWeight: '700', lineHeight: '140%' }],
        'body-sm-semibold': ['13px', { fontWeight: '600', lineHeight: '140%' }],
        'body-sm-medium': ['13px', { fontWeight: '500', lineHeight: '140%' }],
        'body-sm-regular': ['13px', { fontWeight: '400', lineHeight: '140%' }],
        'caption-lg-semibold': [
          '12px',
          { fontWeight: '600', lineHeight: '140%' },
        ],
        'caption-lg-medium': [
          '12px',
          { fontWeight: '500', lineHeight: '140%' },
        ],
        'caption-lg-regular': [
          '12px',
          { fontWeight: '400', lineHeight: '140%' },
        ],
        'caption-md-semibold': [
          '11px',
          { fontWeight: '600', lineHeight: '140%' },
        ],
        'caption-md-medium': [
          '11px',
          { fontWeight: '500', lineHeight: '140%' },
        ],
        'caption-md-regular': [
          '11px',
          { fontWeight: '400', lineHeight: '140%' },
        ],

        // legacy
        'display-md': ['48px', '130%'],
        'heading-xl': ['30px', '130%'],
        'heading-lg': ['24px', '130%'],
        'heading-md': ['20px', '140%'],
        'heading-sm': ['18px', '140%'],
        'body-lg': ['16px', '140%'],
        'body-md': ['14px', '140%'],
        'body-sm': ['13px', '140%'],
        'caption-lg': ['12px', '140%'],
        'caption-md': ['11px', '140%'],
      },
    },
  },

  plugins: [
    ({ addUtilities }: PluginAPI) => {
      addUtilities([
        {
          [`@media (max-width: ${Breakpoints.Mobile}px)`]: {
            // *** Mobile Fonts ***
            '.text-display-md-extrabold': {
              fontSize: '34px',
            },
            '.text-display-sm-extrabold': {
              fontSize: '30px',
            },
            '.text-heading-xl-extrabold': {
              fontSize: '24px',
            },
            '.text-heading-lg-extrabold': {
              fontSize: '22px',
            },
            '.text-heading-md-bold': {
              fontSize: '19px',
            },
            '.text-heading-sm-bold': {
              fontSize: '17px',
            },
            '.text-body-lg-bold': {
              fontSize: '15px',
            },
            '.text-body-lg-semibold': {
              fontSize: '15px',
            },
            '.text-body-lg-medium': {
              fontSize: '15px',
            },
            '.text-body-lg-regular': {
              fontSize: '15px',
            },
            '.text-body-md-bold': {
              fontSize: '13px',
            },
            '.text-body-md-semibold': {
              fontSize: '13px',
            },
            '.text-body-md-medium': {
              fontSize: '13px',
            },
            '.text-body-md-regular': {
              fontSize: '13px',
            },
            '.text-body-sm-bold': {
              fontSize: '12px',
            },
            '.text-body-sm-semibold': {
              fontSize: '12px',
            },
            '.text-body-sm-medium': {
              fontSize: '12px',
            },
            '.text-body-sm-regular': {
              fontSize: '12px',
            },
            '.text-caption-lg-semibold': {
              fontSize: '11px',
            },
            '.text-caption-lg-medium': {
              fontSize: '11px',
            },
            '.text-caption-lg-regular': {
              fontSize: '11px',
            },
            '.text-caption-md-semibold': {
              fontSize: '10px',
            },
            '.text-caption-md-medium': {
              fontSize: '10px',
            },
            '.text-caption-md-regular': {
              fontSize: '10px',
            },
          },
          // *** End of Mobile Fonts ***
        },
      ]);
    },
    require('tailwindcss-animate'),
    require('tailwindcss-safe-area'),
  ],
};

export default config;
