import { useEffect, useState } from 'react';
import * as channelService from '@channel.io/channel-web-sdk-loader';
import { useRouter } from 'next/router';

enum LoadStep {
  BeforeLoad,
  Load,
  Boot,
}

export const useChannelTalk = () => {
  const { isReady, pathname, locale = 'ko', events } = useRouter();
  const disabledPage =
    pathname === '/play/[entryMapHashId]' || pathname.includes('/admin');
  const [loadStatus, setIsLoaded] = useState<LoadStep>(LoadStep.BeforeLoad);

  useEffect(() => {
    if (isReady) {
      if (!disabledPage && loadStatus === LoadStep.BeforeLoad) {
        channelService?.loadScript();
        setIsLoaded(1);
      }
    }
  }, [loadStatus, disabledPage, isReady]);

  useEffect(() => {
    if (loadStatus === LoadStep.Load) {
      channelService?.boot({
        pluginKey: '26103066-96bf-40fa-8e6c-fc7afba2378f',
      });
      setIsLoaded(2);
    }
  }, [loadStatus, locale]);

  useEffect(() => {
    const handleRouteChange = (nextUrl: string) => {
      const localePath = locale === 'ko' ? '' : `/${locale}`;

      const nextDisabledPage =
        (nextUrl.startsWith(`${localePath}/play`) &&
          !nextUrl.endsWith('error/')) ||
        nextUrl.startsWith('/admin');

      if (nextDisabledPage && loadStatus > 0) {
        channelService.shutdown();
      } else {
        setIsLoaded(LoadStep.BeforeLoad);
      }
    };
    events.on('routeChangeStart', handleRouteChange);

    return () => {
      events.off('routeChangeStart', handleRouteChange);
    };
  }, [locale, disabledPage, events, loadStatus]);
};
