import { PropsWithChildren, ReactElement } from 'react';

import { useChannelTalk } from '../lib/channelTalk';

export function getEmptyLayout(page: ReactElement) {
  return <EmptyLayout>{page}</EmptyLayout>;
}

export const EmptyLayout = (props: PropsWithChildren) => {
  useChannelTalk();

  return props.children;
};
